.services {
  position: absolute;
  width: 100%;
  height: 3978px;
  left: 0px;
  top: 920px;

  background: #faefed;
}
.figura-horizontal-right {
  /* Asset 1manicure 1 */

  position: absolute;
  width: 527px;
  height: 218px;
  left: -17px;
  top: 696px;

  background: url("../../asset/image/1-manicure-1.svg");
  opacity: 0.5;
}

.slogan-service {
  /* Group 126 */

  position: absolute;
  width: 429px;
  height: 428px;
}

.slogan-service-box {
  position: absolute;
  width: 429px;
  height: 428px;
  left: 66vh;
  top: 379px;

  background: rgba(255, 255, 255, 0.5);
  border: 10px solid #ff3800;
  box-sizing: border-box;
}

.slogan-text {
  /* MANICURE & PADICURE SERVICES */

  position: absolute;
  width: 276px;
  height: 160px;
  /* left: 663px;
  top: 1413px; */

  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;
  /* or 100% */

  text-align: center;
  letter-spacing: 0.25em;

  color: #323232;
}
