/* Rectangle 19 */

.nav {
  position: absolute;
  width: 100%;
  height: 122px;
  left: 0px;
  top: 0px;
  box-shadow: 0px -1px 20px 0px;
  z-index: 1;

  background: #ffffff;
}
.nav a {
  /* Body L Regular 18px */

  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height, or 139% */
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.nav li {
  float: left;
}

.nav li a {
  display: block;
  padding: 0 79px 0 1px;
  justify-content: space-around;
}

/* Group 115 */

.left-menu {
  position: absolute;
  width: 457px;
  height: 25px;
  left: 244px;
  top: 48px;
}

/* Home */
.active {
  color: #ff3800;
  position: absolute;
  width: 59px;
  height: 3px;
  left: 244px;
  top: 78px;
}

.logo {
  position: absolute;
  width: 283.91px;
  height: 79.07px;
  left: 762px;
  top: 21px;
}

.right-menu {
  position: absolute;
  width: 457px;
  height: 25px;
  left: 1125px;
  top: 48px;
}
